<script setup lang="ts">
import { ref, watch } from 'vue';
import InfoIcon from '@/components/InfoIcon.vue';

interface CardProps {
  imageSrc?: string;
  title: string;
  smallTitle?: boolean;
  width?: string;
  height?: string;
  description: string;
  descriptionSize: string;
  price?: string | number | null;
  type: 'checkbox' | 'radio';
  modelValue: number[];
  index: number;
  toggleImage: boolean;
  shortImage?: boolean;
}

const props = defineProps<CardProps>();
const emit = defineEmits<{ (e: 'update:modelValue', value: number[]): void }>();

const isActive = ref(false);
const showDescription = ref(false);
const toggleImage = ref(false);
const showImage = ref(!props.toggleImage);


const toggleActive = () => {
  if (props.type === 'radio') {
    emit('update:modelValue', [props.index]);
    // if selected
    if (isActive.value) {
      // deselect
      emit('update:modelValue', props.modelValue.filter(i => i !== props.index));
    }
  } else {
    if (isActive.value) {
      emit('update:modelValue', props.modelValue.filter(i => i !== props.index));
    } else {
      emit('update:modelValue', [...props.modelValue, props.index]);
    }
  }
  isActive.value = !isActive.value;
};

const toggleDetails = () => {
  showDescription.value = !showDescription.value;
  toggleImage.value = !toggleImage.value;
  if (props.toggleImage) {
    showImage.value = !showImage.value;
  }
};

watch(
  () => props.modelValue,
  (newVal) => {
    isActive.value = newVal.includes(props.index);
  },
  { immediate: true }
);
</script>

<template>
  <div
    :class="[
      'card co-flex co-flex-col co-bg-light-grey', { 'card--active': isActive },
      showImage ? 'co-rounded-b-lg' : 'co-rounded-lg'
    ]"
    @click="toggleActive"
  >
    <img
      v-if="imageSrc && showImage && !width"
      :src="imageSrc"
      class="co-w-full co-rounded-t-lg co-object-cover"
      :class="[
        toggleImage ? 'co-h-auto' : 'co-h-32',
      ]"
      alt="" />

    <div
      class="co-w-full co-flex-1 co-flex co-flex-col"
    >

      <div
        class="co-flex co-w-full co-justify-between co-items-center co-gap-2 co-p-4"
        :class="width ? 'co-pb-0' : ''"
        >
        <h2
          class="co-flex-1 co-font-bold co-text-lg md:co-text-xl xl:co-text-xl co-text-twf-blue"
          >{{ title }}</h2>
        <InfoIcon
        class="co-w-8 co-h-8 co-self-start"
        @click.stop="toggleDetails"
        />
      </div>
      <div
        v-if="width && height"
        class="co-flex co-flex-col co-px-4">
        <div class="">
          <strong>{{ width }}</strong>
          Width
        </div>
        <div class="">
          <strong>{{ height }}</strong>
          Max Height
        </div>
      </div>

      <img
        v-if="imageSrc && showImage && width"
        :src="imageSrc"
        class="co-w-full co-object-cover co-mt-3 "
        :class="[
          toggleImage ? 'co-h-auto co-mb-4' : 'co-h-32',
        ]"
        alt="" />

      <div
        class="co-flex-1 co-text-base"
        >
        <p
          v-if="showDescription"
          v-html="description"
          class="co-p-4 co-pt-0"
        >
        </p>
      </div>

      <div
        v-if="price"
        class="co-flex co-justify-between co-items-center co-p-4 co-font-semibold co-text-lg co-text-twf-blue"
        :class="toggleImage ? 'co-pt-0' : 'co-pt-4'"
        >
        <div v-if="Number(price) > 0">&pound;{{ Number(price) / 100 }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
